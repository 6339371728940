import React, { useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import moment from 'moment'

import { Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'

export default function PrettyETHTable(props) {
    let pad = function (number) {
        return new Array(5).join('0').slice((5 || 2) * -1) + number
    }

    let timer = null

    let filterInputs = {}

    for (let index = 0; index < props.columns.length; index++) {
        const column = props.columns[index]
        if (column.column) {
            filterInputs[column.column] = ''

            if (column.column === 'created_at' || column.column === 'date') {
                filterInputs[column.column] = {
                    from: new Date(2019, 0, 1),
                    to: new Date(),
                }
            }
        }
    }

    let [count, setCount] = useState(0)
    let [data, setData] = useState([])

    let [showFilter, setShowFilter] = useState(false)
    let [inputsFilter, setInputsFilter] = useState(filterInputs)

    let [limit, setLimit] = useState(10)
    let [page, setPage] = useState(1)
    let [sort, setSort] = useState('-id')
    let [filter, setFilter] = useState({})

    let [contentLoading, setContentLoading] = useState(false)

    let [stats, setStats] = useState([])

    useEffect(() => {
        reloadData(true)

        if (props.stats && props.stats instanceof Array && props.stats.length > 0) {
            setStats(props.stats)
        } else if (props.stats && typeof props.stats === 'object') {
            props.stats.then((data) => {
                setStats(data)
            })
        }
    }, [])

    useEffect(() => {
        reloadData()
    }, [limit, page, sort, filter])

    let onLimitChange = (newLimit) => {
        setLimit(newLimit)
    }

    let onPageChange = (page) => {
        const max = Math.ceil(count / limit)

        if (page < 1) {
            page = 1
        }

        if (page > max) {
            page = max
        }

        setPage(page)
    }

    let onSortChange = (key) => {
        if (!key) {
            return
        }

        if (sort == `-${key}`) {
            sort = `+${key}`
        } else {
            sort = `-${key}`
        }

        setSort(sort)
    }

    let onFilterChange = () => {
        let filters = []

        for (let key in inputsFilter) {
            let value = inputsFilter[key]

            if (value) {
                if (value === 'reset-all') {
                    delete filters[key]
                } else if (typeof value === 'string') {
                    filters[key] = `eq:${value}`
                } else if (value.hasOwnProperty('from') && value.hasOwnProperty('to')) {
                    filters[key] = `between:${moment(value.from).format('YYYY-MM-DD')},${moment(value.to).format('YYYY-MM-DD')}%`
                }
            }
        }

        setFilter(filters)
    }

    let reloadData = (instant) => {
        setData([])
        setContentLoading(true)
        clearTimeout(timer)
        timer = setTimeout(
            () => {
                props.dataLoader(props.dataLoaderParams, { limit: limit, sort: sort, page: page, filter: filter }).then((res) => {
                    setContentLoading(false)
                    setData(res.data)
                    setCount(res.count)
                })
            },
            instant ? 0 : 500
        )
    }

    return (
        <div className='content-area-wrapper'>
            <div className='main-content'>
                <div className='page-title-sec pad-tb-1 space-between d-flex'>
                    <h3>
                        <span class='adjust-he1'>
                            {props.title} ({count})
                        </span>
                        {props.addButton}
                    </h3>

                    <div className='page-filter-options'>
                        <a className='th-toggle-btn' href='#' data-toggle='#advanced-filders' onClick={() => setShowFilter(!showFilter)}>
                            <svg className='icon filter-icon' style={{ 'vertical-align': 'middle', display: 'inline-block' }}>
                                <use href='/images/svg-sprite.svg#filter-icon'></use>
                            </svg>
                            <span style={{ 'vertical-align': 'middle', display: 'inline-block', 'margin-left': '10px', color: '#06bbee' }}>
                                ({Object.keys(filter).length} Filtres)
                            </span>
                        </a>
                    </div>
                </div>

                <div id='advanced-filders' className={`advanced-filters bg-white-sec ${!showFilter && 'd-none'}`}>
                    <div className='d-flex space-between flex-wrap ad-filter-row'>
                        {props.columns.map(
                            (column) =>
                                column.column &&
                                column.filter && (
                                    <div className='ad-filter-col'>
                                        <label>{column.title} :</label>

                                        {(() => {
                                            if (column.filter.type === 'string:equal') {
                                                return (
                                                    <div className='ad-filter-field th-selctbox-wrap'>
                                                        <input
                                                            className='th-textbox th-textbox-white th-selectbox'
                                                            value={inputsFilter[column.column]}
                                                            onChange={(e) => {
                                                                setInputsFilter({ ...inputsFilter, [column.column]: e.target.value })
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            } else if (column.filter.type === 'string:select') {
                                                return (
                                                    <div className='ad-filter-field th-selctbox-wrap'>
                                                        <select
                                                            className='th-textbox th-textbox-white th-selectbox'
                                                            value={inputsFilter[column.column]}
                                                            onChange={(e) => {
                                                                setInputsFilter({ ...inputsFilter, [column.column]: e.target.value })
                                                            }}>
                                                            <option value={'reset-all'}> Tous </option>

                                                            {column.filter.options.map((option) => (
                                                                <option value={option.value}>{option.title}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )
                                            } else if (column.filter.type === 'date:between') {
                                                return (
                                                    <div className='ad-filter-field'>
                                                        <div className='d-flex space-between'>
                                                            {/* <input type="text" name="start_date" className="th-textbox th-textbox-white f-date-field datepicker" placeholder="XX/XX/20" /> */}
                                                            <DatePicker
                                                                dateFormat='dd/MM/yyyy'
                                                                className='th-textbox th-textbox-white f-date-field datepicker'
                                                                selected={inputsFilter[column.column].from}
                                                                // onChange={date => setStartDate(date)}
                                                            />

                                                            <span className='at-text'>au</span>
                                                            {/* <input type="text" name="end_date" className="th-textbox th-textbox-white f-date-field datepicker" placeholder="XX/XX/20" /> */}

                                                            <DatePicker
                                                                dateFormat='dd/MM/yyyy'
                                                                className='th-textbox th-textbox-white f-date-field datepicker'
                                                                selected={inputsFilter[column.column].to}
                                                                // onChange={date => setStartDate(date)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                )
                        )}

                        <div className='ad-filter-col'>
                            <div className='w-50-inner align-center'>
                                <span>
                                    <a className='btn-inline-block th-toggle-btn' href='#' data-toggle='#advanced-filders' onClick={() => setShowFilter(false)}>
                                        Masquer
                                    </a>
                                </span>
                                <input
                                    type='submit'
                                    className='theme-btn theme-btn-gradient'
                                    value='Valider'
                                    onClick={() => {
                                        onFilterChange()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='page-content-sec pad-tb-1 grid-bg-sec1'>
                <div className='main-content'>
                    {stats && stats.length > 0 && (
                        <div className='d-flex space-between eq-he-cols flex-wrap info-boxes-wrapper mb-50'>
                            {stats.map((el) => {
                                return (
                                    <div className='info-box-cont' style={{ opacity: `${el.hidden ? '0' : '1'}` }}>
                                        <div className='info-box-img'>
                                            <img src={el.icon} alt='' />
                                        </div>
                                        <div className='info-box-text'>
                                            <h4>{el.data}</h4>
                                            <p>{el.title}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <div className='table-wrapper s-table-content-wrapper'>
                        <div className='d-table full-width s-table-content'>
                            <div className='d-table-row table-header'>
                                <div className='d-table-cell align-center'></div>
                                {props.columns
                                    .filter((c) => !c.hidden)
                                    .map((column) => {
                                        return (
                                            <div className='d-table-cell' onClick={() => onSortChange(column.column)}>
                                                {column.title}
                                            </div>
                                        )
                                    })}
                                <div className='d-table-cell align-center'></div>
                            </div>

                            {data &&
                                data.length > 0 &&
                                data.map((single) => {
                                    return (
                                        <div className='d-table-row table-data-row' style={props.rowStyle && props.rowStyle(single)}>
                                            <div className='d-table-cell align-center'></div>

                                            {props.columns
                                                .filter((c) => !c.hidden)
                                                .map((column) => {
                                                    let value = null

                                                    if (column.value) {
                                                        value = column.value(single)
                                                    } else {
                                                        value = single[column['column']]
                                                    }

                                                    if (column.column === 'id') {
                                                        return <div className='d-table-cell'>{pad(value)}</div>
                                                    } else {
                                                        return <div className='d-table-cell'>{value}</div>
                                                    }
                                                })}

                                            <div className='d-table-cell align-center'></div>
                                        </div>
                                    )
                                })}
                        </div>

                        {!contentLoading && data.length == 0 && (
                            <div class='align-center no-record-found table-no-record'>
                                <p>Aucun résultat</p>
                            </div>
                        )}

                        {contentLoading && data.length == 0 && (
                            <div class='align-center no-record-found table-no-record'>
                                <p>
                                    {' '}
                                    <Bounce color='rgb(6, 187, 238)' size='28px' />{' '}
                                </p>
                            </div>
                        )}
                    </div>

                    {data && data.length > 0 && (
                        <div className='table-records align-right'>
                            <span>Afficher par : </span>

                            {[10, 25, 50, 100].map((filter) => {
                                return (
                                    <a className={`clickable ${limit === filter ? 'record-active' : ''}`} onClick={() => onLimitChange(filter)}>
                                        {filter}
                                    </a>
                                )
                            })}
                        </div>
                    )}

                    {data && data.length > 0 && (
                        <div className='table-pagination'>
                            <ul>
                                <li className='prev'>
                                    <a href='#' onClick={() => onPageChange(page - 1)}>
                                        Précédent
                                    </a>
                                </li>

                                {Array.apply(0, Array(Math.ceil(count / limit))).map((x, i) => {
                                    if (i + 1 >= page - 1 && i + 1 <= page + 5) {
                                        return (
                                            <li key={i}>
                                                {i + 1 === page ? (
                                                    <span>{i + 1}</span>
                                                ) : (
                                                    <a href='#' onClick={() => onPageChange(i + 1)}>
                                                        {i + 1}
                                                    </a>
                                                )}
                                            </li>
                                        )
                                    }
                                })}

                                <li className='next'>
                                    <a href='#' onClick={() => onPageChange(page + 1)}>
                                        Suivant
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
