import React, { useEffect, useState } from 'react'

import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import S3Picture from '../../Components/S3Picture'

import { useGoogleMaps } from '../../Services/GoogleMap'

import gmapstyle from '../../gmapstyle'

import Select from 'react-select'

import { toast } from 'react-toastify'

import VoucherEditPopup from '../../Components/Modals/VoucherEdit'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { useHistory } from 'react-router-dom'

export function List(props) {
    const history = useHistory()

    return (
        <PrettyETHTable
            title='Code Promo'
            dataLoader={FasterApi.getVouchers}
            dataLoaderParams={props.match.params}
            addButton={
                <button
                    className='theme-btn'
                    onClick={() => {
                        history.push('/admin/vouchers/create')
                    }}>
                    Créer une promotion
                </button>
            }
            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal',
                    },
                },
                {
                    title: 'Code',
                    column: 'code',
                    filter: {
                        type: 'string:equal',
                    },
                },
                {
                    title: 'Actif',
                    column: 'status',
                    value: ({ status, end_at }) => (status ? (new Date(end_at) < new Date() ? 'Expiré' : 'Actif') : 'Inactif'),
                },
                {
                    title: 'Création',
                    column: 'created_at',
                    value: ({ created_at }) => moment(created_at).format('DD/MM/YYYY'),
                },
                {
                    title: 'Début | Fin',
                    value: ({ begin_at, end_at }) => {
                        return (
                            <>
                                {moment(begin_at).format('DD/MM/YYYY')} <br /> {moment(end_at).format('DD/MM/YYYY')}
                            </>
                        )
                    },
                },
                {
                    title: 'Utilisations',
                    column: 'usage_count',
                },
                {
                    title: 'Montant',
                    value: ({ amount, discount_type }) => {
                        return amount + ' ' + (discount_type === 1 ? 'CFA' : 'CFA')
                    },
                },
                {
                    title: '',
                    value: (voucher) => (
                        <VoucherEditPopup
                            className='theme-btn'
                            voucher={voucher}
                            onClose={(data) => {
                                window.location.reload()
                            }}>
                            Voir
                        </VoucherEditPopup>
                    ),
                },
            ]}></PrettyETHTable>
    )
}

export function Create() {
    const history = useHistory()

    const [voucher, setVoucher] = useState({
        code: '',
        description: '',
        status: true,
        amount: 0,

        date_from: new Date(),
        date_to: moment().endOf('month').toDate(),
    })

    function createVoucher() {
        let amount = voucher.amount

        if (typeof amount == 'string') {
            amount = parseFloat(amount.replace(',', '.'))

            if (isNaN(amount)) {
                return toast.error('Le montant est invalide (Surement un problème de format).', {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }

        FasterApi.postVoucher({
            ...voucher,
            ...{
                amount: amount,
            },
        })
            .then((voucher) => {
                toast.success('Le code promo a été créé.', {
                    position: toast.POSITION.TOP_RIGHT,
                })

                history.push('/admin/vouchers')
            })
            .catch((error) => {
                toast.error('Une erreur est survenue lors de la création du code promo (Peut-être que le code existe déjà).', {
                    position: toast.POSITION.TOP_RIGHT,
                })
            })
    }

    function changeVoucher(name, value) {
        setVoucher({
            ...voucher,
            [name]: value,
        })
    }

    function onDateFromChange(date) {
        console.log(date, voucher.date_to)
        if (date > voucher.date_to) {
            return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

        changeVoucher('date_from', date)
    }

    function onDateToChange(date) {
        if (date < voucher.date_from) {
            return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

        changeVoucher('date_to', date)
    }

    function formIsValid() {
        return voucher.code != '' && voucher.description != '' && voucher.amount != 0
    }

    return (
        <div className='content-area-wrapper faster-logo-bg pb-20'>
            <div class='main-content'>
                <div class='page-title-sec pad-tb-1 space-between d-flex t-flex-start'>
                    <div class='page-sec-nav'></div>
                </div>

                <div class='bg-white-sec mb-50'>
                    <div class='summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between'>
                        <div class='full-width-imp d-flex flex-wrap space-between'>
                            <h3 class='theme-color pb-20 mb-0'>Création d’un code promo</h3>
                            <h3 class='theme-color pb-20 mb-0'></h3>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Statut</label>
                        </div>
                        <div class='summary-col'>
                            <input type='radio' className='s-ezee-radio' name='p-status' id='actif' checked={voucher.status === true} />
                            <label
                                htmlFor='actif'
                                onClick={() => {
                                    changeVoucher('status', true)
                                }}>
                                Actif
                            </label>
                            <input type='radio' className='s-ezee-radio' name='p-status' id='non-actif' checked={voucher.status === false} />
                            <label
                                htmlFor='non-actif'
                                onClick={() => {
                                    changeVoucher('status', false)
                                }}>
                                Non actif
                            </label>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Nom du code</label>
                        </div>
                        <div class='summary-col'>
                            <input
                                type='text'
                                className='th-textbox'
                                placeholder='ex : OFF20'
                                value={voucher.code}
                                onChange={(e) => {
                                    changeVoucher('code', e.target.value)
                                }}
                            />
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Raison</label>
                        </div>
                        <div class='summary-col' style={{ width: '75%' }}>
                            <textarea
                                style={{ width: '100%' }}
                                className='th-textbox'
                                placeholder='ex : offre de bienvenue'
                                value={voucher.description}
                                onChange={(e) => {
                                    changeVoucher('description', e.target.value)
                                }}></textarea>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Montant en CFA</label>
                        </div>
                        <div class='summary-col'>
                            <input
                                type='text'
                                className='th-textbox'
                                placeholder='Veuillez indiquer le montant'
                                value={voucher.amount}
                                onChange={(e) => {
                                    changeVoucher('amount', e.target.value)
                                }}
                            />
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Validité</label>
                        </div>
                        <div class='summary-col' style={{ width: '37.5%' }}>
                            <div className='dropdown-arrow date-picker-wrap'>
                                <span style={{ marginRight: 15 }}>Du</span>

                                <DatePicker
                                    style={{ marginLeft: '15px' }}
                                    dateFormat='dd/MM/yyyy'
                                    className='th-textbox datepicker'
                                    selected={voucher.date_from}
                                    onChange={onDateFromChange}
                                    locale='fr'
                                />
                            </div>
                        </div>
                        <div class='summary-col' style={{ width: '37.5%' }}>
                            <div className='dropdown-arrow date-picker-wrap'>
                                <span style={{ marginRight: 15 }}>Au</span>

                                <DatePicker
                                    style={{ marginLeft: '15px' }}
                                    dateFormat='dd/MM/yyyy'
                                    className='th-textbox datepicker'
                                    selected={voucher.date_to}
                                    onChange={onDateToChange}
                                    locale='fr'
                                />
                            </div>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <input type='button' className='theme-btn' value='Valider le code' disabled={!formIsValid()} onClick={createVoucher} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
