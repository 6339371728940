import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import S3Picture from '../../Components/S3Picture'

import { useGoogleMaps } from '../../Services/GoogleMap'

import gmapstyle from '../../gmapstyle'

import Select from 'react-select'

import { toast } from 'react-toastify'

const decodePolyline = require('decode-google-map-polyline')

export function List(props) {
    const location = useLocation()

    return (
        <PrettyETHTable
            title='Reservations'
            dataLoader={FasterApi.getBookings}
            dataLoaderParams={props.match.params}
            // stats={new Promise((resolve) => {

            //     FasterApi.call("GET", "/transactions/summary").then(({data}) => {

            //         resolve([
            //             {icon: '/images/p-tab-icon1.svg', title: 'Nombre total de commandes', data:  data.count},
            //             {icon: '/images/p-tab-icon2.svg', title: 'Commandes annulées', data:  data.count_refunded},
            //             // {hidden: true},
            //             // {hidden: true},
            //             {icon: '/images/p-tab-icon3.svg', title: 'Chiffre d\'affaire réalisé', data:  data.ca + ' CFA'},
            //             {icon: '/images/p-tab-icon4.svg', title: 'Frais Faster', data:  data.amount_faster_fees + ' CFA'},
            //             // {icon: '/images/p-tab-icon4.svg', title: 'Frais bancaires (stripe)', data:  data.fees_stripe / 100 + ' CFA'},
            //             // {hidden: true},
            //             // {hidden: true},
            //         ])

            //     })

            // })}

            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal',
                    },
                },
                {
                    title: 'Client / Chauffeur',
                    value: (booking) => {
                        let users = [
                            <Link className='user-p-link' to={'/admin/users/' + booking.Passenger.id}>
                                <S3Picture className='user-profile-img' type='user-profil' file={booking.Passenger.picture} />
                                {booking.Passenger.firstname} {booking.Passenger.lastname}
                            </Link>,
                            <br />,
                            <br />,
                        ]

                        if (booking.Driver) {
                            users.push(
                                <Link className='user-p-link' to={'/admin/users/' + booking.Driver.id}>
                                    <S3Picture className='user-profile-img' type='user-profil' file={booking.Driver.picture} />
                                    {booking.Driver.firstname} {booking.Driver.lastname}
                                </Link>
                            )
                        }

                        return users
                    },
                },

                {
                    title: 'Adresses',
                    // column: "amount_paid",
                    value: ({ address_from, address_to }) => [address_from, <br />, address_to],
                },

                {
                    title: 'Prix',
                    column: 'amount_paid',
                    value: (el) => ((el.Transaction && el.Transaction.amount_paid) || 0) + ' CFA',
                },

                {
                    title: 'Com. Faster',
                    value: (el) => ((el.Transaction && el.Transaction.faster_fees_amount_total) || 0) + ' CFA',
                },

                // {
                //     title: 'Moyen de Paiement',
                //     column: "payment_type",
                //     value: ({ payment_type }) => {
                //         switch(payment_type) {
                //             case "CARD":
                //                 return "Paydunya"
                //             case "WALLET":
                //                 return "Porte-Feuille"
                //             case "CASH":
                //                 return "Espèce"
                //         }
                //     }
                // },

                {
                    title: 'Date',
                    column: 'trip_date',
                    value: ({ trip_date }) => moment(trip_date).format('LLL'),
                    filter: {
                        type: 'date:between',
                    },
                },

                {
                    title: 'Statut',
                    column: 'status',
                    value: ({ status }) =>
                        (status === 2 && 'Réservation sans chauffeur') ||
                        (status === 3 && 'En attente de la course') ||
                        (status === 31 && 'Le chauffeur est proche') ||
                        (status === 32 && 'Le client a été pris en charge') ||
                        (status === 4 && 'Terminée') ||
                        (status === 9 && 'Annulée') ||
                        (status === 99 && 'Remboursée'),
                },
                {
                    title: '',
                    value: (single) => <Link to={location.pathname + '/' + single.id}>Voir</Link>,
                },
            ]}
            rowStyle={({ status }) => {
                return { background: (status === 2 && 'rgba(251, 98, 98, 0.75)') || (status === 3 && 'rgba(144, 238, 144, 0.43)') }
            }}></PrettyETHTable>
    )
}

export function Single(props) {
    let [booking, setBooking] = useState({})
    let [center, setCenter] = useState({ lat: 59.95, lng: 30.33 })
    let [point_from, setPoint_from] = useState({ lat: 59.95, lng: 30.33 })
    let [point_to, setPoint_to] = useState({ lat: 59.95, lng: 30.33 })
    let [drivers, setDrivers] = useState([])
    let [selectedDriver, selectDriver] = useState(null)
    let [selectedDriverId, selectDriverId] = useState(null)

    const { ref, map, google } = useGoogleMaps('AIzaSyA7tucuOJJjLvqefVHw_3I48xkeVa-vB_o', {
        center: center,
        zoom: 10,
        styles: gmapstyle,
    })

    if (map) {
        new google.maps.Marker({ position: point_from, map })
        new google.maps.Marker({ position: point_to, map })
        new google.maps.Polyline({
            path: decodePolyline(booking.polyline),
            geodesic: true,
            strokeColor: '#01C2AD',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: map,
        })
    }

    let reload = () => {
        FasterApi.getBooking(props.match.params).then((data) => {
            setBooking(data)
            if (data) {
                setCenter({ lat: data.point_from.coordinates[0], lng: data.point_from.coordinates[1] })
                setPoint_from({ lat: data.point_from.coordinates[0], lng: data.point_from.coordinates[1] })
                setPoint_to({ lat: data.point_to.coordinates[0], lng: data.point_to.coordinates[1] })

                FasterApi.getDrivers(null, {
                    filter: {
                        status: 'eq:true',
                        // 'vehicle_type_id': `eq:${data.vehicle_type_id}`
                    },
                    limit: 200,
                    page: 1,
                }).then((data) => {
                    setDrivers(data.data)
                })
            }
        })
    }

    useEffect(() => {
        reload()
    }, [])

    if (!booking.id) {
        return <div></div>
    }

    return (
        <div class='content-area-wrapper faster-logo-bg pb-20'>
            <div class='main-content'>
                <div class='page-title-sec pad-tb-1 space-between d-flex t-flex-start'>
                    <div class='page-sec-nav'>{/* <a href="#"><svg class="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}</div>
                </div>

                <div class='bg-white-sec mb-50'>
                    <div class='summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between'>
                        <div class='full-width-imp d-flex flex-wrap space-between'>
                            <h3 class='theme-color pb-20 mb-0'>Détail de la course</h3>
                            <h3 class='color3 pb-20 mb-0'>
                                {(() => {
                                    switch (booking.status) {
                                        case 2:
                                            return 'Réservation sans chauffeur'
                                        case 3:
                                            return 'En attente de la course'
                                        case 31:
                                            return 'Le chauffeur est proche'
                                        case 32:
                                            return 'Le client a été pris en charge'
                                        case 4:
                                            return 'Terminée'
                                        case 9:
                                            return 'Annulée'
                                        case 99:
                                            return 'Annulée & Remboursée'
                                    }
                                })()}
                            </h3>
                        </div>
                        {(booking.status == 2 || booking.status == 3) && (
                            <div class='full-width-imp d-flex flex-wrap space-between'>
                                <button style={{ visibility: 'hidden' }}></button>

                                <button
                                    class='theme-btn min-w-230'
                                    style={{ backgroundColor: '#f5585c' }}
                                    onClick={() => {
                                        window.confirm('Voulez-vous vraiment annuler cette course ?') &&
                                            FasterApi.deleteBooking({ booking_id: booking.id }).then(() => {
                                                reload()
                                                toast.success('🚀 La course a été annulée.')
                                            })
                                    }}>
                                    Annuler
                                </button>
                            </div>
                        )}
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Réf course</label>
                        </div>
                        <div class='summary-col'>
                            <p>{booking.id}</p>
                        </div>
                        <div class='summary-col'>
                            <label>Tarif</label>
                        </div>
                        <div class='summary-col'>
                            <p>{booking.amount_to_pay}</p>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Client</label>
                        </div>
                        <div class='summary-col'>
                            <p>
                                <Link className='user-p-link theme-color fw-600' to={'/admin/users/' + booking.Passenger.id}>
                                    <S3Picture className='user-profile-img' type='user-profil' file={booking.Passenger.picture} />
                                    {booking.Passenger.firstname + ' ' + booking.Passenger.lastname}
                                </Link>
                            </p>
                        </div>

                        <div class='summary-col'>
                            <label>
                                Coordonnées <br />
                                client
                            </label>
                        </div>
                        <div class='summary-col' style={{ maxWidth: '20%' }}>
                            <p>
                                <a>{booking.Passenger.email}</a>
                                <br />
                                <a>{booking.Passenger.phone_number}</a>
                            </p>
                        </div>
                    </div>

                    {(() => {
                        if (booking.Driver) {
                            return (
                                <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                    <div class='summary-col'>
                                        <label>Chauffeur</label>
                                    </div>
                                    <div class='summary-col'>
                                        <p>
                                            <Link className='user-p-link theme-color fw-600' to={'/admin/users/' + booking.Driver.id}>
                                                <S3Picture className='user-profile-img' type='user-profil' file={booking.Driver.picture} />
                                                {booking.Driver.firstname + ' ' + booking.Driver.lastname}
                                            </Link>
                                        </p>
                                    </div>

                                    <div class='summary-col'>
                                        <label>
                                            Coordonnées <br />
                                            chauffeur
                                        </label>
                                    </div>
                                    <div class='summary-col' style={{ maxWidth: '20%' }}>
                                        <p>
                                            <a>{booking.Driver.email}</a>
                                            <br />
                                            <a>{booking.Driver.phone_number}</a>
                                        </p>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                    <div class='summary-col'>
                                        <label>Chauffeur</label>
                                    </div>
                                    <div class='summary-col'>
                                        <p>Aucun chauffeur attribué</p>
                                    </div>

                                    <div class='summary-col'>
                                        <label>
                                            <br />
                                        </label>
                                    </div>
                                    <div class='summary-col' style={{ maxWidth: '20%' }}>
                                        <p>
                                            <Popup className='popup-box' trigger={<button class='theme-btn min-w-230 mt-10'>Séléctionner un chaffeur</button>} modal nested>
                                                {(close) => {
                                                    // this.sclose = close
                                                    return (
                                                        <div
                                                            class='sm-content-block sm-content-block-w1 lg-white-bg mt-0 align-center'
                                                            style={{
                                                                margin: '0',
                                                                padding: '0',
                                                                maxWidth: '100%',
                                                                width: '100%',
                                                                maxHeight: '90vh',
                                                                overflowY: 'auto',
                                                            }}>
                                                            {/* <div className="close-popup popup-info_close"><img src="/images/close-icon.svg" alt="Close" onClick={close} /></div> */}
                                                            <div class='close-popup popup-info1_close' onClick={close}>
                                                                <svg class='icon close-icon'>
                                                                    <use href='/images/svg-sprite.svg#close-icon'></use>
                                                                </svg>
                                                            </div>
                                                            <div class='popup-box-inner align-center'>
                                                                <h3 class='align-center'>Quel chauffeur souhaitez vous mettre sur cette course ? </h3>

                                                                <div class='reset-message'>
                                                                    <div className='row sky-bg-section align-left'>
                                                                        <div className='col-12'>
                                                                            <br />

                                                                            <p>Veuillez choisir un chauffeur pour cette course, attention cette action est irreversible ! </p>

                                                                            <Select
                                                                                isSearchable={false}
                                                                                placeholder='Séléctionnez un chauffeur'
                                                                                value={selectedDriverId}
                                                                                onChange={(data) => {
                                                                                    selectDriver(data.value)
                                                                                    selectDriverId(data)
                                                                                }}
                                                                                options={drivers.map((driver, i) => {
                                                                                    return {
                                                                                        value: driver,
                                                                                        label: (
                                                                                            <>
                                                                                                <span
                                                                                                    class='t-client-icon'
                                                                                                    style={{ width: '35px', display: 'inline-block', 'vertical-align': 'middle' }}>
                                                                                                    <S3Picture type='user-profil' file={driver.User.picture} />
                                                                                                </span>
                                                                                                <span style={{ 'vertical-align': 'middle', 'margin-left': '12px' }}>
                                                                                                    {driver.User.firstname} {driver.User.lastname}
                                                                                                </span>
                                                                                            </>
                                                                                        ),
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <br />

                                                                            <p>
                                                                                Après avoir définit le chauffeur, le passager sera débité et recevera un email avec la facture,
                                                                                soyez sur du choix que vous faites !{' '}
                                                                            </p>

                                                                            <p>
                                                                                {selectedDriver ? (
                                                                                    <button
                                                                                        class='theme-btn min-w-230 mt-10'
                                                                                        onClick={() => {
                                                                                            FasterApi.putBooking(
                                                                                                { booking_id: booking.id },
                                                                                                { status: 3, driver_id: selectedDriver.user_id }
                                                                                            ).then(() => {
                                                                                                // this.props.onClose()
                                                                                                // this.sclose()
                                                                                                reload()
                                                                                                close()

                                                                                                toast.success(
                                                                                                    `🚀 ${selectedDriver.User.firstname} ${selectedDriver.User.lastname} est le chauffeur de cette course.`
                                                                                                )
                                                                                            })
                                                                                        }}>
                                                                                        Séléctionner -{' '}
                                                                                        <span
                                                                                            class='t-client-icon'
                                                                                            style={{ width: '35px', display: 'inline-block', 'vertical-align': 'middle' }}>
                                                                                            <S3Picture type='user-profil' file={selectedDriver.User.picture} />
                                                                                        </span>
                                                                                        <span style={{ 'vertical-align': 'middle', 'margin-left': '12px' }}>
                                                                                            {selectedDriver.User.firstname} {selectedDriver.User.lastname}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <>
                                                                                        <br />
                                                                                        <br />
                                                                                        <br />
                                                                                        <br />
                                                                                        <br />
                                                                                    </>
                                                                                )}

                                                                                {/* Chauffeur: {selectedDriver} */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    {selectedDriver && (
                                                                        <a className='theme-button1 theme-button-green' onClick={() => this.refund()} style={{ color: 'white' }}>
                                                                            Définir le chauffeur !{' '}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Popup>
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    })()}

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Trajet effectué</label>
                        </div>
                        <div class='summary-col loc-pointer-icon'>
                            <div class='loc-pointer'>
                                <img src='/images/location-pointer.svg' alt='' />
                            </div>
                            <label class='color-purple'>Départ</label>
                            <p class='fs-16'>{booking.address_from}</p>
                            <label class='color-purple mt-25 d-block'>Arrivée</label>
                            <p class='fs-16'>{booking.address_to}</p>
                        </div>
                        <div class='summary-col'>
                            <label>
                                Date et heure <br />
                                de la course
                            </label>
                            <label class='mt-25 d-block'>
                                Kilomètres et durée <br />
                                de la course
                            </label>
                        </div>
                        <div class='summary-col'>
                            <p>{moment(booking.trip_date).format('DD/MM/YYYY') + ' à ' + moment(booking.trip_date).format('HH:mm')}</p>
                            <p style={{ 'padding-top': '55px' }}>
                                {(booking.distance / 1000).toFixed(0)} km - {(booking.duration / 60).toFixed(0)} min
                            </p>
                        </div>
                    </div>

                    {/* {booking.Claims.length > 0 && <Link style={{ marginTop: 20, }} className="user-p-link" to={'/admin/claims/' + booking.Claims[0].id}>
                    <div class="summary-col">
                        <p><a class=" theme-color " href="#" style={{ textDecorationLine: 'underline' }}>Voir la réclamation</a></p>
                    </div>
                </Link>} */}

                    <div ref={ref} style={{ width: '100%', height: 500, marginTop: 20 }} />
                </div>
            </div>
        </div>
    )
}
