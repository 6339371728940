import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import { toast } from 'react-toastify'

import FasterApi from '../Services/FasterApi'
import S3Picture from '../Components/S3Picture'

export function Big(props) {
    let { user } = useSelector((state) => {
        return {
            user: state.user,
        }
    })

    let logout = () => {
        FasterApi.unauth().then(() => {
            props.history.push('/login')
        })
    }

    return (
        <div className='header-wrapper'>
            <div className='h-logo' onClick={() => props.history.push('/')}>
                <img src='/images/logo.png' alt='' />
            </div>
            <div className='header-row'>
                <div className='h-user-profile header-sec'>
                    <div className='h-user-pic'>
                        {/* <img src="/images/profile-pic.png" alt=""  /> */}
                        <S3Picture type='user-profil' file={user.picture} />
                    </div>
                    <div className='h-user-info'>
                        <div className='th-toggle-btn' data-toggle='#user-dropdown-menu'>
                            <svg className='icon icon-arrow-down icon-white'>
                                <use href='/images/svg-sprite.svg#d-arrow-black'></use>
                            </svg>
                            <h5>
                                Bonjour {user.firstname} {user.lastname}
                            </h5>
                            <p>Votre compte</p>
                        </div>
                        <div id='user-dropdown-menu' className='h-user-dropdown elem-toggle'>
                            <ul>
                                <li>
                                    <Popup
                                        className='popup-box'
                                        trigger={
                                            <a className='popup-info1_open' href='#popup-info1'>
                                                <svg className='icon icon-logout-icon'>
                                                    <use href='/images/svg-sprite.svg#logout-icon'></use>
                                                </svg>
                                                Déconnexion
                                            </a>
                                        }
                                        modal
                                        nested>
                                        {(close) => (
                                            <div
                                                class='sm-content-block sm-content-block-w1 lg-white-bg mt-0 align-center'
                                                style={{
                                                    margin: '0',
                                                    padding: '0',
                                                    maxWidth: '100%',
                                                    width: '100%',
                                                    maxHeight: '90vh',
                                                    overflowY: 'auto',
                                                }}>
                                                <div class='close-popup popup-info1_close' onClick={close}>
                                                    <svg class='icon close-icon'>
                                                        <use href='/images/svg-sprite.svg#close-icon'></use>
                                                    </svg>
                                                </div>
                                                <div class='popup-box-inner align-center'>
                                                    <h3 class='align-center'>
                                                        Êtes-vous sûr de <br />
                                                        vouloir vous déconnecter ?
                                                    </h3>
                                                    <div class='popup-hand-img'>
                                                        <img src='/images/popup-hand-img.svg' alt='' />
                                                    </div>
                                                    <div class='reset-message'>
                                                        <p>
                                                            Si vous vous déconnectez, <br />
                                                            vous devrez vous reconnecter <br />
                                                            afin d'accéder au site partenaire.
                                                        </p>
                                                    </div>
                                                    <div class='w-50-inner'>
                                                        <span onClick={close}>
                                                            <a href='#' class='popup-info1_close btn-inline-block'>
                                                                Annuler
                                                            </a>
                                                        </span>
                                                        <a
                                                            href='#'
                                                            class='theme-btn theme-btn-gradient'
                                                            onClick={() => {
                                                                localStorage.clear()
                                                                props.history.push('/')
                                                            }}>
                                                            Déconnexion
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Admin(props) {
    return (
        <div class='header-navigation'>
            <div class='align-right show-tablet'>
                <div class='toggle-menu-btn'>
                    <label class='menu__btn' for='menu__toggle'>
                        <span></span>
                    </label>
                </div>
            </div>
            <ul>
                <li className={/^\/admin\/transactions/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/transactions`}>
                        <svg class='icon nav-icon nav-icon1'>
                            <use href='/images/svg-sprite.svg#nav-icon1'></use>
                        </svg>
                        Transactions
                    </Link>
                </li>

                <li className={/^\/admin\/vouchers/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/vouchers`}>
                        <svg class='icon nav-icon nav-icon3'>
                            <use href='/images/svg-sprite.svg#nav-icon3'></use>
                        </svg>
                        Code Promo
                    </Link>
                </li>

                <li className={/^\/admin\/users/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/users`}>
                        <svg class='icon nav-icon nav-icon2'>
                            <use href='/images/svg-sprite.svg#nav-icon2'></use>
                        </svg>
                        Clients
                    </Link>
                </li>

                <li className={/^\/admin\/drivers/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/drivers`}>
                        <svg class='icon nav-icon nav-icon3'>
                            <use href='/images/svg-sprite.svg#nav-icon3'></use>
                        </svg>
                        Chauffeurs
                    </Link>
                </li>

                {/* <li className={ /^\/admin\/trips/.test(props.location.pathname) && "active-menu-item" }>
                    <Link to={`${props.match.url}/trips`}>
                        <svg class="icon nav-icon nav-icon3"><use href="/images/svg-sprite.svg#nav-icon3"></use></svg>Courses
                    </Link>
                </li> */}

                <li className={/^\/admin\/trips/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/trips`}>
                        <svg class='icon nav-icon nav-icon3'>
                            <use href='/images/svg-sprite.svg#nav-icon3'></use>
                        </svg>
                        Trajets
                    </Link>
                </li>

                <li className={/^\/admin\/bookings/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/bookings`}>
                        <svg class='icon nav-icon nav-icon3'>
                            <use href='/images/svg-sprite.svg#nav-icon3'></use>
                        </svg>
                        Réservations
                    </Link>
                </li>

                <li className={/^\/admin\/claims/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/claims`}>
                        <svg class='icon nav-icon nav-icon3'>
                            <use href='/images/svg-sprite.svg#nav-icon3'></use>
                        </svg>
                        Réclamations
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export function Driver(props) {
    let { user } = useSelector((state) => {
        return {
            user: state.user,
        }
    })

    return (
        <div class='header-navigation'>
            <div class='align-right show-tablet'>
                <div class='toggle-menu-btn'>
                    <label class='menu__btn' for='menu__toggle'>
                        <span></span>
                    </label>
                </div>
            </div>
            <ul>
                <li className={/^\/driver\/summary/.test(props.location.pathname) && 'active-menu-item'}>
                    <Link to={`${props.match.url}/summary`}>
                        <svg class='icon nav-icon nav-icon2'>
                            <use href='/images/svg-sprite.svg#nav-icon2'></use>
                        </svg>
                        Mes informations
                    </Link>
                </li>

                {/* {
                    user.is_driver && (
                        <>
                            <li className={ /^\/driver\/trips/.test(props.location.pathname) && "active-menu-item" }>
                                <Link to={`${props.match.url}/trips`}>
                                    <svg class="icon nav-icon nav-icon3"><use href="/images/svg-sprite.svg#nav-icon3"></use></svg>Mes trajets
                                </Link>
                            </li>

                            <li className={ /^\/admin\/incomes/.test(props.location.pathname) && "active-menu-item" }>
                                <Link to={`${props.match.url}/incomes`}>
                                    <svg class="icon nav-icon nav-icon1"><use href="/images/svg-sprite.svg#nav-icon1"></use></svg>Mes revenues
                                </Link>
                            </li>
                        </>
                    ) 
                }
                */}
            </ul>
        </div>
    )
}

// export function Partner(props) {

//     return (
//         <div className="header-navigation nav-ver2">
//             <div className="align-right show-tablet">
//                 <div className="toggle-menu-btn">
//                 <label className="menu__btn" for="menu__toggle">
//                     <span></span>
//                     </label>
//                 </div>
//             </div>
//             <ul>
//             {/* className={ /^\/partners\/companies\/(\d+)\/vouchers/.test(props.location.pathname) && "active-menu-item"  */}

//             <li className={ /^\/partners\/companies\/(\d+)\/settings/.test(props.location.pathname) && "active-menu-item" }>
//                     <Link to={`${props.match.url}/settings`}>
//                         <svg className="icon nav-icon nav-icon8"><use href="/images/svg-sprite.svg#nav-icon8"></use></svg>Paramètres
//                     </Link>
//                 </li>

//                 <li className={ /^\/partners\/companies\/(\d+)\/bookings/.test(props.location.pathname) && "active-menu-item"}  >
//                     <Link to={`${props.match.url}/bookings`}>
//                         <svg className="icon nav-icon nav-icon1"><use href="/images/svg-sprite.svg#nav-icon1"></use></svg>Réservations
//                     </Link>
//                 </li>

//                 {/* <li className={ /^\/partners\/companies\/(\d+)\/vouchers/.test(props.location.pathname) && "active-menu-item" }>
//                     <Link to={`${props.match.url}/vouchers`}>
//                         <svg className="icon nav-icon nav-icon2"><use href="/images/svg-sprite.svg#nav-icon2"></use></svg>Code promo
//                     </Link>
//                 </li> */}

//                 <li className={ /^\/partners\/companies\/(\d+)\/withdraws/.test(props.location.pathname) && "active-menu-item"}  >
//                     <Link to={`${props.match.url}/withdraws`}>
//                         <svg className="icon nav-icon nav-icon3"><use href="/images/svg-sprite.svg#nav-icon3"></use></svg>Virements
//                     </Link>
//                 </li>

//                 <li className={ /^\/partners\/companies\/(\d+)\/planning/.test(props.location.pathname) && "active-menu-item"}  >
//                     <Link to={`${props.match.url}/planning`}>
//                         <svg className="icon nav-icon nav-icon7"><use href="/images/svg-sprite.svg#nav-icon7"></use></svg>Planning
//                     </Link>
//                 </li>

//                 <li className={ /^\/partners\/companies\/(\d+)\/stadiums/.test(props.location.pathname) && "active-menu-item" }>
//                     <Link to={`${props.match.url}/stadiums`}>
//                         <svg className="icon nav-icon nav-icon9"><use href="/images/svg-sprite.svg#nav-icon9"></use></svg>Stades
//                     </Link>
//                 </li>

//                 <li className={ /^\/partners\/companies\/(\d+)\/users/.test(props.location.pathname) && "active-menu-item"}  >
//                     <Link to={`${props.match.url}/users`}>
//                         <svg className="icon nav-icon nav-icon5"><use href="/images/svg-sprite.svg#nav-icon5"></use></svg>Clients
//                     </Link>
//                 </li>

//             </ul>
//         </div>
//     )

// }
