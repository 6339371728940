import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import S3Picture from '../../Components/S3Picture'

export function List(props) {
    const location = useLocation()

    return (
        <PrettyETHTable
            title='Transactions'
            dataLoader={FasterApi.getTransactions}
            dataLoaderParams={props.match.params}
            stats={
                new Promise((resolve) => {
                    FasterApi.call('GET', '/transactions/summary').then(({ data }) => {
                        resolve([
                            { icon: '/images/p-tab-icon1.svg', title: 'Nombre total de commandes', data: data.count },
                            { icon: '/images/p-tab-icon2.svg', title: 'Commandes annulées', data: data.count_refunded },
                            // {hidden: true},
                            // {hidden: true},
                            { icon: '/images/p-tab-icon3.svg', title: "Chiffre d'affaire réalisé", data: data.ca + ' CFA' },
                            { icon: '/images/p-tab-icon4.svg', title: 'Frais Faster', data: data.amount_faster_fees + ' CFA' },
                            // {icon: '/images/p-tab-icon4.svg', title: 'Frais bancaires (stripe)', data:  data.fees_stripe / 100 + ' CFA'},
                            // {hidden: true},
                            // {hidden: true},
                        ])
                    })
                })
            }
            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal',
                    },
                },
                {
                    title: 'Client / Chauffeur',
                    value: ({ CreditedUser, DebitedUser }) => {
                        let users = [<br />, <br />]

                        if (DebitedUser) {
                            users.unshift(
                                <Link className='user-p-link' to={'/admin/users/' + DebitedUser.id}>
                                    <S3Picture className='user-profile-img' type='user-profil' file={DebitedUser.picture} />
                                    {DebitedUser.firstname} {DebitedUser.lastname}
                                </Link>
                            )
                        }

                        if (CreditedUser) {
                            users.push(
                                <Link className='user-p-link' to={'/admin/users/' + CreditedUser.id}>
                                    <S3Picture className='user-profile-img' type='user-profil' file={CreditedUser.picture} />
                                    {CreditedUser.firstname} {CreditedUser.lastname}
                                </Link>
                            )
                        }

                        return users
                    },
                },

                {
                    title: 'Prix',
                    column: 'amount_paid',
                    value: ({ amount_paid }) => amount_paid + ' CFA',
                },

                {
                    title: 'Com. Faster',
                    column: 'faster_fees_amount_total',
                    value: ({ faster_fees_amount_total }) => faster_fees_amount_total + ' CFA',
                },

                {
                    title: 'Moyen de Paiement',
                    column: 'payment_type',
                    value: ({ payment_type }) => {
                        switch (payment_type) {
                            case 'CARD':
                                return 'Paydunya'
                            case 'WALLET':
                                return 'Porte-Feuille'
                            case 'CASH':
                                return 'Espèce'
                        }
                    },
                },

                {
                    title: 'Date',
                    column: 'date',
                    value: ({ created_at }) => moment(created_at).format('LLL'),
                    filter: {
                        type: 'date:between',
                    },
                },

                {
                    title: 'Statut',
                    column: 'status',
                    value: (single) => {
                        if (single.refunded === true) {
                            return 'Remboursé'
                        } else {
                            return `Accepté ${single.driver_withdraw_id ? '(Payé)' : ''}`
                        }
                    },
                    // filter: {
                    //     type: 'string:select',
                    //     options: [{
                    //         value: 0,
                    //         title: "En cours"
                    //     },{
                    //         value: 1,
                    //         title: "En cours 2"
                    //     }]
                    // }
                },
                {
                    title: '',
                    value: (single) => <Link to={location.pathname + '/' + single.id}>Voir</Link>,
                },
            ]}></PrettyETHTable>
    )
}

export function Single(props) {
    let [transaction, setTransaction] = useState({})

    let reload = () => {
        FasterApi.getTransaction(props.match.params).then((transaction) => {
            setTransaction(transaction)
        })
    }

    useEffect(() => {
        reload()
    }, [])

    if (!transaction.id) {
        return <div></div>
    }

    return (
        <div class='content-area-wrapper faster-logo-bg pb-20'>
            <div class='main-content'>
                <div class='page-title-sec pad-tb-1 space-between d-flex t-flex-start'>
                    <div class='page-sec-nav'>{/* <a href="#"><svg class="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}</div>
                </div>

                <div class='bg-white-sec mb-50'>
                    <div class='summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between'>
                        <div class='full-width-imp d-flex flex-wrap space-between'>
                            <h3 class='theme-color pb-20 mb-0'>Détail de la transaction</h3>
                            <h3 class='theme-color pb-20 mb-0'>
                                {(() => {
                                    if (transaction.refunded === true) {
                                        return 'Remboursé'
                                    } else {
                                        return `Accepté ${transaction.driver_withdraw_id ? '(Payé)' : ''}`
                                    }
                                })()}
                            </h3>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Réf Transaction</label>
                        </div>
                        <div class='summary-col'>
                            <p>{transaction.id}</p>
                        </div>
                        <div class='summary-col'>
                            <label>Trajet</label>
                        </div>
                        {transaction.Trip && (
                            <div class='summary-col'>
                                <Link to={'/admin/trips/' + transaction.Trip.id} style={{ color: 'rgb(107, 122, 235)' }}>
                                    Voir les détails du trajet
                                </Link>
                            </div>
                        )}
                        {transaction.Booking && (
                            <div class='summary-col'>
                                <Link to={'/admin/bookings/' + transaction.Booking.id} style={{ color: 'rgb(107, 122, 235)' }}>
                                    Voir les détails de la réservation
                                </Link>
                            </div>
                        )}
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Tarification</label>
                        </div>
                        <div class='summary-col'>
                            <label class='color-purple'>Prix Payé</label>
                            <p class='fs-16'>{transaction.amount_paid} CFA</p>
                            <label class='color-purple mt-25 d-block'>Code promo (Réduction)</label>
                            <p class='fs-16'>- {transaction.amount_voucher} CFA</p>
                            <label class='color-purple mt-25 d-block'>Prix Normal</label>
                            <p class='fs-16'>{transaction.amount_total} CFA</p>
                        </div>

                        <div class='summary-col'>
                            <label>Chiffre d'affaire</label>
                        </div>
                        <div class='summary-col'>
                            <label class='color-purple'>CA généré</label>
                            <p class='fs-16'>{transaction.amount_paid} CFA</p> <br />
                            <label class='color-purple'>Bénéfice</label>
                            <p class='fs-16'>{transaction.faster_fees_amount_total} CFA</p>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Client</label>
                        </div>

                        <div class='summary-col'>
                            <p>
                                <Link className='user-p-link theme-color fw-600' to={'/admin/users/' + transaction.DebitedUser.id}>
                                    <S3Picture className='user-profile-img' type='user-profil' file={transaction.DebitedUser.picture} />
                                    {transaction.DebitedUser.firstname + ' ' + transaction.DebitedUser.lastname}
                                </Link>
                            </p>
                        </div>

                        <div class='summary-col'>
                            <label>
                                Coordonnées <br />
                                client
                            </label>
                        </div>
                        <div class='summary-col' style={{ maxWidth: '20%' }}>
                            <p>
                                <a>{transaction.DebitedUser.email}</a>
                                <br />
                                <a>{transaction.DebitedUser.phone_number}</a>
                            </p>
                        </div>
                    </div>

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Trajet effectué</label>
                        </div>
                        <div class='summary-col loc-pointer-icon'>
                            <div class='loc-pointer'>
                                <img src='/images/location-pointer.svg' alt='' />
                            </div>
                            <label class='color-purple'>Départ</label>
                            <p class='fs-16'>{(transaction.Trip || transaction.Booking).address_from}</p>
                            <label class='color-purple mt-25 d-block'>Arrivée</label>
                            <p class='fs-16'>{(transaction.Trip || transaction.Booking).address_to}</p>
                        </div>
                        <div class='summary-col'>
                            <label>
                                Date et heure <br />
                                de la course
                            </label>
                            <label class='mt-25 d-block'>
                                Kilomètres et durée <br />
                                de la course
                            </label>
                        </div>
                        <div class='summary-col'>
                            {transaction.Trip && <p>{moment(transaction.Trip.created_at).format('DD/MM/YYYY') + ' à ' + moment(transaction.Trip.created_at).format('HH:mm')}</p>}
                            {transaction.Booking && (
                                <p>{moment(transaction.Booking.trip_date).format('DD/MM/YYYY') + ' à ' + moment(transaction.Booking.trip_date).format('HH:mm')}</p>
                            )}
                            <p style={{ 'padding-top': '55px' }}>
                                {((transaction.Trip || transaction.Booking).distance / 1000).toFixed(0)} km - {((transaction.Trip || transaction.Booking).duration / 60).toFixed(0)}{' '}
                                min
                            </p>
                        </div>
                    </div>

                    {transaction.CreditedUser && (
                        <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                            <div class='summary-col'>
                                <label>Chauffeur</label>
                            </div>

                            <div class='summary-col'>
                                <p>
                                    <Link className='user-p-link theme-color fw-600' to={'/admin/drivers/' + transaction.CreditedUser.id}>
                                        <S3Picture className='user-profile-img' type='user-profil' file={transaction.CreditedUser.picture} />
                                        {transaction.CreditedUser.firstname + ' ' + transaction.CreditedUser.lastname}
                                    </Link>
                                </p>
                            </div>

                            <div class='summary-col'>
                                <label>
                                    Coordonnées <br />
                                    chauffeur
                                </label>
                            </div>
                            <div class='summary-col' style={{ maxWidth: '20%' }}>
                                <p>
                                    <a>{transaction.CreditedUser.email}</a>
                                    <br />
                                    <a>{transaction.CreditedUser.phone_number}</a>
                                </p>
                            </div>
                        </div>
                    )}

                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                        <div class='summary-col'>
                            <label>Payé au chauffeur</label>
                        </div>
                        <div class='summary-col' style={{ width: '75%' }}>
                            <p>
                                {transaction.driver_withdraw_id ? 'La course a été payé au chauffeur.' : "La course n'a pas été payé au chauffeur (Aucun virement pour le moment)."}{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
