import React, { Component, useState } from 'react'
import Popup from 'reactjs-popup'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import FasterApi from '../../Services/FasterApi'
import S3Picture from '../S3Picture'
import Moment from 'react-moment'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'

function VoucherEditPopup(props) {
    let sclose = null

    const [voucher, setVoucher] = useState({
        ...props.voucher,

        begin_at: new Date(props.voucher.begin_at),
        end_at: new Date(props.voucher.end_at),
    })

    function updateVoucher() {
        FasterApi.putVoucher(props.voucher.id, {
            status: voucher.status,
            description: voucher.description,

            begin_at: voucher.begin_at,
            end_at: voucher.end_at,
        }).then(() => {
            toast.success('Le code promo a été modifié avec succès')
            props.onClose()
            sclose()
        })
    }

    function changeVoucher(name, value) {
        setVoucher({
            ...voucher,
            [name]: value,
        })
    }

    function onDateFromChange(date) {
        if (date > voucher.date_to) {
            return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

        changeVoucher('begin_at', date)
    }

    function onDateToChange(date) {
        if (date < voucher.date_from) {
            return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

        changeVoucher('end_at', date)
    }

    function formIsValid() {
        return voucher.code != '' && voucher.description != '' && voucher.amount != 0
    }

    return (
        <Popup
            modal={true}
            lockScroll={true}
            trigger={
                <button style={{ border: 0 }} className={props.className}>
                    {' '}
                    {props.children}{' '}
                </button>
            }>
            {(close) => {
                sclose = close
                return (
                    <div
                        class='sm-content-block sm-content-block-w1 lg-white-bg mt-0 align-center'
                        style={{
                            margin: '0',
                            padding: '0',
                            maxWidth: '100%',
                            width: '100%',
                            maxHeight: '90vh',
                            overflowY: 'auto',
                        }}>
                        <div class='close-popup popup-info1_close' onClick={close}>
                            <svg class='icon close-icon'>
                                <use href='/images/svg-sprite.svg#close-icon'></use>
                            </svg>
                        </div>
                        <div class='popup-box-inner align-left' style={{ padding: 0 }}>
                            <div class='main-content'>
                                <div class='bg-white-sec mb-50'>
                                    <div class='summary-row mlr-25-minus d-flex flex-wrap eq-he-cols space-between'>
                                        <div class='full-width-imp d-flex flex-wrap space-between'>
                                            <h3 class='theme-color pb-20 mb-0'>Modifier un code promo</h3>
                                            <h3 class='theme-color pb-20 mb-0'></h3>
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <label>Statut</label>
                                        </div>
                                        <div class='summary-col' style={{ width: '75%' }}>
                                            <label
                                                htmlFor='actif'
                                                onClick={() => {
                                                    changeVoucher('status', true)
                                                }}>
                                                <input type='radio' className='s-ezee-radio' name='p-status' id='actif' checked={voucher.status === true} />
                                                Actif
                                            </label>
                                            <label
                                                htmlFor='non-actif'
                                                onClick={() => {
                                                    changeVoucher('status', false)
                                                }}>
                                                <input type='radio' className='s-ezee-radio' name='p-status' id='non-actif' checked={voucher.status === false} />
                                                Non actif
                                            </label>
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <label>Nom du code</label>
                                        </div>
                                        <div class='summary-col' style={{ width: '75%' }}>
                                            <input
                                                type='text'
                                                className='th-textbox'
                                                readOnly
                                                placeholder='ex : OFF20'
                                                value={voucher.code}
                                                contentEditable={false}
                                                onChange={(e) => {
                                                    changeVoucher('code', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <label>Raison</label>
                                        </div>
                                        <div class='summary-col' style={{ width: '75%' }}>
                                            <textarea
                                                style={{ width: '100%' }}
                                                className='th-textbox'
                                                placeholder='ex : offre de bienvenue'
                                                value={voucher.description}
                                                onChange={(e) => {
                                                    changeVoucher('description', e.target.value)
                                                }}></textarea>
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <label>Montant en CFA</label>
                                        </div>
                                        <div class='summary-col' style={{ width: '75%' }}>
                                            <input
                                                type='text'
                                                className='th-textbox'
                                                placeholder='Veuillez indiquer le montant'
                                                value={voucher.amount}
                                                contentEditable={false}
                                                onChange={(e) => {
                                                    changeVoucher('amount', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <label>Validité</label>
                                        </div>
                                        <div class='summary-col' style={{ width: '37.5%' }}>
                                            <div className='dropdown-arrow date-picker-wrap'>
                                                <span style={{ marginRight: 15 }}>Du</span>

                                                <DatePicker
                                                    style={{ marginLeft: '15px' }}
                                                    dateFormat='dd/MM/yyyy'
                                                    className='th-textbox datepicker'
                                                    selected={voucher.begin_at}
                                                    onChange={onDateFromChange}
                                                    locale='fr'
                                                />
                                            </div>
                                        </div>
                                        <div class='summary-col' style={{ width: '37.5%' }}>
                                            <div className='dropdown-arrow date-picker-wrap'>
                                                <span style={{ marginRight: 15 }}>Au</span>

                                                <DatePicker
                                                    style={{ marginLeft: '15px' }}
                                                    dateFormat='dd/MM/yyyy'
                                                    className='th-textbox datepicker'
                                                    selected={voucher.end_at}
                                                    onChange={onDateToChange}
                                                    locale='fr'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class='summary-row summary-row-eq-cols mlr-25-minus d-flex flex-wrap eq-he-cols'>
                                        <div class='summary-col'>
                                            <input type='button' className='theme-btn' value='Valider le code' disabled={!formIsValid()} onClick={updateVoucher} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Popup>
    )
}

export default VoucherEditPopup
