import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import moment from 'moment'

import FasterApi from '../../Services/FasterApi'

import PrettyETHTable from '../../Components/PrettyETHTable'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import S3Picture from '../../Components/S3Picture'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

export function List(props) {
    const location = useLocation()

    return (
        <PrettyETHTable
            title='Clients'
            dataLoader={FasterApi.getUsers}
            dataLoaderParams={props.match.params}
            columns={[
                {
                    title: 'Réf',
                    column: 'id',
                    filter: {
                        type: 'string:equal',
                    },
                },
                {
                    title: 'Nom / Prénom',
                    column: 'firstname',
                    value: ({ id, firstname, lastname, picture, is_professional, professional_name }) => {
                        return (
                            <Link className='user-p-link' to={location.pathname + '/' + id}>
                                <S3Picture className='user-profile-img' type='user-profil' file={picture} />
                                {is_professional ? '(Pro) ' + professional_name : lastname + ' ' + firstname}
                            </Link>
                        )
                    },
                },

                {
                    title: 'Nom',
                    column: 'lastname',
                    hidden: true,
                    filter: {
                        type: 'string:equal',
                    },
                },

                {
                    title: 'Prénom',
                    column: 'firstname',
                    hidden: true,
                    filter: {
                        type: 'string:equal',
                    },
                },

                {
                    title: 'Entreprise',
                    column: 'professional_name',
                    hidden: true,
                    filter: {
                        type: 'string:equal',
                    },
                },

                // {
                //     title: 'Date',
                //     column: 'created_at',
                //     value: ({ created_at }) => moment(created_at).format('DD/MM/YYYY'),
                //     filter: {
                //         type: 'date:between'
                //     }

                // },
                {
                    title: 'Email',
                    column: 'email',
                },
                {
                    title: 'N° de téléphone ',
                    column: 'phone_number',
                },
                {
                    title: 'Note',
                    column: 'passenger_rate',
                    value: ({ passenger_rate }) => parseFloat(passenger_rate).toFixed(2) + ' / 5',
                },
                {
                    title: 'Courses',
                    column: 'trips_amount',
                },
                {
                    title: 'Solde',
                    column: 'wallet_balance',
                    value: ({ wallet_balance }) => {
                        let wb = wallet_balance || 0

                        if (wb === 0) {
                            return <span>0 CFA</span>
                        } else {
                            return (
                                <span style={{ color: `${wb > 0 ? 'green' : 'red'}` }}>
                                    {wb > 0 ? '+' : '-'} {wb + ' CFA'}
                                </span>
                            )
                        }
                    },
                },
                {
                    title: 'C.A GÉNÉRÉ',
                    column: 'debited_amount',
                    value: ({ debited_amount }) => debited_amount + ' CFA',
                },
            ]}></PrettyETHTable>
    )
}

export function Single(props) {
    let [user, setUser] = useState({})
    let [menu, setMenu] = useState(1)
    let [reviews, setReviews] = useState([])

    let reload = () => {
        FasterApi.getUser(props.match.params).then(({ data }) => {
            setUser(data)
            if (data) {
                FasterApi.getUserRates(data.id).then(({ data }) => {
                    setReviews(data.rows)
                })
            }
        })
    }

    useEffect(() => {
        reload()
    }, [])

    if (!user.id) {
        return <div></div>
    }

    return (
        <div className='content-area-wrapper faster-logo-bg'>
            <div className='main-content'>
                <div className='page-title-sec pad-tb-1 space-between d-flex t-flex-start'>
                    <div className='page-sec-nav'>
                        {/* <a href="#"><svg className="icon icon-arrow-back"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg>Retour</a> */}
                    </div>
                </div>
            </div>

            <div className='page-content-sec'>
                <div className='main-content mb-50'>
                    <div className='two-columns-wrapper d-flex space-between eq-he-cols'>
                        <div className='profile-sidebar box-shadow3 bg-white-sec'>
                            <h3 className='align-center'>
                                {/*<img className="diamond-icon mr-10" src="/images/diamond-icon.svg" alt="" /> */}
                                {/* {user.firstname} {user.lastname} */}
                                {user.is_professional ? '(Pro) ' + user.professional_name : user.lastname + ' ' + user.firstname}
                            </h3>
                            <div className='profile-img-sec m-auto'>
                                <div className='profile-pic img-preview'>
                                    <S3Picture type='user-profil' file={user.picture} />
                                </div>
                            </div>

                            <ul className='mlr-25-minus mt-40'>
                                <li className={`${menu === 1 && 'p-active-link'}`}>
                                    <a onClick={() => setMenu(1)}>
                                        Informations générales{' '}
                                        <svg className='icon icon-arrow-down icon-white'>
                                            <use href='/images/svg-sprite.svg#d-arrow-black'></use>
                                        </svg>
                                    </a>
                                </li>
                                {/* <li className={`${menu === 2 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(2)} >Courses <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li>
                                <li className={`${menu === 3 && "p-active-link"}`}>
                                    <a onClick={() => setMenu(3)} >Parrainage <svg className="icon icon-arrow-down icon-white"><use href="/images/svg-sprite.svg#d-arrow-black"></use></svg></a>
                                </li> */}
                                <li className={`${menu === 4 && 'p-active-link'}`}>
                                    <a onClick={() => setMenu(4)}>
                                        Avis{' '}
                                        <svg className='icon icon-arrow-down icon-white'>
                                            <use href='/images/svg-sprite.svg#d-arrow-black'></use>
                                        </svg>
                                    </a>
                                </li>
                                <li className={`${menu === 7 && 'p-active-link'}`}>
                                    <a onClick={() => setMenu(7)}>
                                        Solde
                                        {(() => {
                                            let wb = user.wallet_balance || 0

                                            if (wb === 0) {
                                                return <span> 0 CFA</span>
                                            } else {
                                                return (
                                                    <span style={{ color: `${wb > 0 ? 'green' : 'red'}` }}>
                                                        {' '}
                                                        {wb > 0 ? '+' : '-'} {wb + ' CFA'}
                                                    </span>
                                                )
                                            }
                                        })()}
                                        <svg className='icon icon-arrow-down icon-white'>
                                            <use href='/images/svg-sprite.svg#d-arrow-black'></use>
                                        </svg>
                                    </a>
                                </li>
                            </ul>

                            <div className='align-center mt-25 profile-bottom-link'>{/* <a href="#" className="btn-inline-block fw-400 color3">Bannir ce client</a> */}</div>
                        </div>

                        {menu == 1 && <Informations {...props} user={user} />}
                        {menu == 2 && <Trips {...props} user={user} />}
                        {menu == 3 && <Sponsorship {...props} user={user} />}
                        {menu == 4 && <Rate {...props} user={user} reviews={reviews} />}

                        {menu == 7 && <Wallet {...props} user={user} reload={reload} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Informations(props) {
    return (
        <div class='profile-cont-sec box-shadow3 bg-white-sec'>
            <h3 class='theme-color fw-600'>Informations générales</h3>

            <div id='compte-personnel-section' class='tabs-section'>
                <div class='form-wrapper'>
                    <div class='d-flex flex-wrap eq-he-cols space-between'>
                        {props.user.is_professional ? (
                            <>
                                <div class='form-col form-col1'>
                                    <label>Nom de l'entreprise</label>
                                    <div class='form-control control-icon-right'>
                                        <svg class='icon icon-field-right edit-icon'>
                                            <use href='/images/svg-sprite.svg#edit-icon'></use>
                                        </svg>
                                        <input type='text' class='th-textbox' name='Prenom' placeholder='Prénom' value={props.user.professional_name} />
                                    </div>
                                </div>

                                <div class='form-col form-col2'>
                                    <label>Adresse de l'entreprise</label>
                                    <div class='form-control control-icon-right'>
                                        <svg class='icon icon-field-right edit-icon'>
                                            <use href='/images/svg-sprite.svg#edit-icon'></use>
                                        </svg>
                                        <input type='text' class='th-textbox' name='Nom' value={props.user.address} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}

                        <div class='form-col form-col1'>
                            <label>Prénom</label>
                            <div class='form-control control-icon-right'>
                                <svg class='icon icon-field-right edit-icon'>
                                    <use href='/images/svg-sprite.svg#edit-icon'></use>
                                </svg>
                                <input type='text' class='th-textbox' name='Prenom' placeholder='Prénom' value={props.user.firstname} />
                            </div>
                        </div>

                        <div class='form-col form-col2'>
                            <label>Nom</label>
                            <div class='form-control control-icon-right'>
                                <svg class='icon icon-field-right edit-icon'>
                                    <use href='/images/svg-sprite.svg#edit-icon'></use>
                                </svg>
                                <input type='text' class='th-textbox' name='Nom' value={props.user.lastname} />
                            </div>
                        </div>

                        <div class='form-col form-col1'>
                            <label>Numéro de téléphone</label>
                            <div class='form-control control-icon-right'>
                                <svg class='icon icon-field-right edit-icon'>
                                    <use href='/images/svg-sprite.svg#edit-icon'></use>
                                </svg>
                                <input type='text' class='th-textbox' name='téléphone' value={props.user.phone_number} />
                            </div>
                        </div>

                        <div class='form-col form-col2'>
                            <label>Adresse e-mail</label>
                            <div class='form-control control-icon-right'>
                                <svg class='icon icon-field-right edit-icon'>
                                    <use href='/images/svg-sprite.svg#edit-icon'></use>
                                </svg>
                                <input type='text' class='th-textbox' name='equipements' placeholder='e-mail' value={props.user.email} />
                            </div>
                        </div>
                    </div>

                    <a href='#' class='theme-btn popup-info4_open'>
                        Réinitialiser le mot de passe
                    </a>
                    {/* <div class="align-right mt-40 btn-fix-mb">
                        <span class="min-w-230 align-center d-inline-block mt-10"><a href="#" class="btn-inline-block mr-10">Abandonner</a></span>
                        <input type="submit" class="theme-btn min-w-230 mt-10" value="Valider" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

function Trips(props) {
    return <div></div>
}

function Sponsorship(props) {
    return <div></div>
}

function Rate(props) {
    return (
        <div className='profile-cont-sec box-shadow3 bg-white-sec'>
            <h3 className='theme-color fw-600'>Avis</h3>

            <div className='sec-border-fix pt-20'>
                <div className='avis-rows-wrapper mlr-60-minus'>
                    {(() => {
                        if (props.reviews.length > 0) {
                            return props.reviews.map((el) => {
                                return (
                                    <div className='avis-row d-flex flex-wrap flex-start-align space-between' style={{ 'border-bottom': '1px solid #00000029' }}>
                                        <div className='avis-user-info'>
                                            <div className='avis-user-img d-inline-block'>
                                                <S3Picture type='user-profil' file={el.Driver.picture} />
                                            </div>
                                            <div className='avis-user-meta d-inline-block'>
                                                <h3>{el.Driver.firstname + ' ' + el.Driver.lastname}</h3>
                                                <span className='d-block fw-600 theme-color'>
                                                    <svg className='icon icon-star mr-10'>
                                                        <use href='/images/svg-sprite.svg#star-icon'></use>
                                                    </svg>{' '}
                                                    {parseFloat(el.rate).toFixed(1)}
                                                </span>
                                                <span className='d-block'>le {moment(el.created_at).format('DD/MM/YYYY')}</span>
                                            </div>
                                        </div>
                                        <div className='avis-user-desc'>
                                            <p>{el.comment}</p>

                                            <div class='summary-col'>
                                                <p>
                                                    <Link
                                                        style={{ marginTop: 20 }}
                                                        className='theme-color'
                                                        to={'/admin/trips/' + el.trip_id}
                                                        style={{ textDecorationLine: 'underline' }}>
                                                        Voir la course
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        } else {
                            return (
                                <div className='avis-row d-flex flex-wrap flex-start-align space-between'>
                                    <div className='avis-user-desc'>
                                        <p>L'utilisateur n'a encore aucun avis</p>
                                    </div>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}

function Wallet(props) {
    let [transactions, setTransactions] = useState([])

    let reload = () => {
        FasterApi.getWalletTransactions(props.user.id).then(({ data }) => {
            setTransactions(data)
        })
    }

    let { register, handleSubmit, watch, errors, formState, reset } = useForm({
        mode: 'all',
    })

    useEffect(() => {
        reload()
    }, [])

    return (
        <div class='table-wrapper s-table-content-wrapper profile-cont-sec' style={{ padding: '0' }}>
            <div class='d-table full-width s-table-content'>
                <div class='d-table-row'>
                    <div class='d-table-cell' style={{ textAlign: 'left', padding: '0 10px 0 10px' }}>
                        Solde:
                        {(() => {
                            let wb = props.user.wallet_balance || 0

                            if (wb === 0) {
                                return <span> 0 CFA</span>
                            } else {
                                return (
                                    <span style={{ color: `${wb > 0 ? 'green' : 'red'}` }}>
                                        {' '}
                                        {wb > 0 ? '+' : '-'} {wb + ' CFA'}
                                    </span>
                                )
                            }
                        })()}
                    </div>

                    <div class='d-table-cell' style={{ textAlign: 'right', padding: '0 10px 0 0' }}>
                        <Popup
                            className='popup-box'
                            trigger={
                                <button class='theme-btn min-w-230 mt-10' style={{ minWidth: '190px', marginBottom: '10px' }}>
                                    Ajouter une transaction
                                </button>
                            }
                            modal
                            nested>
                            {(close) => (
                                <div
                                    class='sm-content-block sm-content-block-w1 lg-white-bg mt-0 align-center'
                                    style={{
                                        margin: '0',
                                        padding: '0',
                                        maxWidth: '100%',
                                        width: '100%',
                                        maxHeight: '90vh',
                                        overflowY: 'auto',
                                    }}>
                                    <div class='close-popup popup-info1_close' onClick={close}>
                                        <svg class='icon close-icon'>
                                            <use href='/images/svg-sprite.svg#close-icon'></use>
                                        </svg>
                                    </div>
                                    <div class='popup-box-inner align-center'>
                                        <form
                                            onSubmit={handleSubmit((data) => {
                                                let send = {
                                                    amount: data.amount,
                                                    reason: data.reason,
                                                }

                                                if (data.type === 'debited') {
                                                    send.debited_user_wallet_id = props.user.id
                                                } else if (data.type === 'credited') {
                                                    send.credited_user_wallet_id = props.user.id
                                                }

                                                console.log(data.type)

                                                console.log(send)

                                                FasterApi.createWalletTransactions(props.user.id, send).then((res) => {
                                                    reload()
                                                    props.reload()
                                                    toast.success('🚀 Transaction réussite.')
                                                    close()
                                                    reset()
                                                })
                                            })}>
                                            <h3 class='align-center'>
                                                Ajouter une transaction à {props.user.lastname} {props.user.firstname}{' '}
                                            </h3>

                                            <div class='reset-message'>
                                                <label>
                                                    {' '}
                                                    <input ref={register({ required: true })} type='radio' name='type' value='debited' /> Débité{' '}
                                                </label>
                                                <label>
                                                    <input ref={register({ required: true })} type='radio' name='type' value='credited' /> Crédité{' '}
                                                </label>{' '}
                                                <br /> <br />
                                                <input ref={register({ required: true })} type='number' name='amount' placeholder='Montant en CFA' className='th-textbox' />
                                                <br />
                                                <br />
                                                <textarea ref={register({ required: true })} name='reason' placeholder='Raison de la transaction' className='th-textbox'></textarea>
                                                <br />
                                                <br />
                                            </div>

                                            <div class='w-50-inner'>
                                                <span onClick={close}>
                                                    <a href='#' class='popup-info1_close btn-inline-block'>
                                                        Annuler
                                                    </a>
                                                </span>

                                                <input
                                                    type='submit'
                                                    href='#'
                                                    class='theme-btn theme-btn-gradient'
                                                    value='Créer la transaction'
                                                    style={{ background: !formState.isValid && '#9396AD' }}
                                                    disabled={!formState.isValid}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>
            </div>

            <div class='d-table full-width s-table-content'>
                <div class='d-table-row table-header'>
                    <div class='d-table-cell'></div>

                    <div class='d-table-cell'>
                        <span class='c-d-arrow' style={{ fontSize: 16 }}>
                            ID{' '}
                            <svg class='icon icon-arrow-down icon-white'>
                                <use href='images/svg-sprite.svg#d-arrow-black'></use>
                            </svg>
                        </span>
                    </div>

                    <div class='d-table-cell'>
                        <span class='c-d-arrow' style={{ fontSize: 16 }}>
                            Montant{' '}
                            <svg class='icon icon-arrow-down icon-white'>
                                <use href='images/svg-sprite.svg#d-arrow-black'></use>
                            </svg>
                        </span>
                    </div>

                    <div class='d-table-cell'>
                        <span class='c-d-arrow' style={{ fontSize: 16 }}>
                            Raison{' '}
                            <svg class='icon icon-arrow-down icon-white'>
                                <use href='images/svg-sprite.svg#d-arrow-black'></use>
                            </svg>
                        </span>
                    </div>

                    <div class='d-table-cell'></div>
                </div>

                {transactions.map((el) => {
                    return (
                        <div class='d-table-row table-data-row'>
                            <div class='d-table-cell'></div>
                            <div class='d-table-cell'> {el.id} </div>
                            <div class='d-table-cell'>
                                {' '}
                                <span style={{ color: `${el.credited_user_wallet_id === props.user.id ? 'green' : 'red'}` }}>
                                    {el.credited_user_wallet_id === props.user.id ? '+' : '-'} {el.amount + ' CFA'}
                                </span>{' '}
                            </div>
                            <div class='d-table-cell'>{el.reason}</div>
                            <div class='d-table-cell'></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
